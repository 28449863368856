import {
	Component,
	Ref,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'AppModule',
	mixins: [
		Template,
	]
})
export default class InvitationModule extends Vue {
	@Ref('canvas')
	private readonly canvasElement!: HTMLCanvasElement;

	protected mounted(): void {
		setTimeout(
			this.drawCanvas,
			500,
		);
	}

	private async drawCanvas(): Promise<void> {
		const image = new Image();
		image.onload = () => {
			this.canvasElement.width = image.width;
			this.canvasElement.height = image.height;
			const canvasContext = this.canvasElement.getContext('2d');

			if (canvasContext) {
				let letterSpacing = 4;
				let fontSize = 60;
				const downscaleWidth = 530;
				const sizeFactor = downscaleWidth / image.width;
				const namePaddingTop = 0.45;
				canvasContext.drawImage(
					image,
					0,
					0,
					image.width,
					image.height,
				);
				canvasContext.fillStyle = '#D79E5A';
				canvasContext.font = `${fontSize / sizeFactor}px Julietta`;
				canvasContext.textAlign = 'center';
				(canvasContext as any).letterSpacing = `${letterSpacing / sizeFactor}px`;
				let textTopAlignment = image.height * namePaddingTop;
				canvasContext.fillText(
					'Mariana',
					image.width / 2,
					textTopAlignment,
				);
				textTopAlignment += fontSize / sizeFactor;
				canvasContext.fillText(
					'&',
					image.width / 2,
					textTopAlignment,
				);
				textTopAlignment += fontSize / sizeFactor;
				canvasContext.fillText(
					'Julián',
					image.width / 2,
					textTopAlignment,
				);
				textTopAlignment += fontSize / sizeFactor;
				fontSize = 18;
				canvasContext.font = `${fontSize / sizeFactor}px Oranienbaum Regular`;
				(canvasContext as any).letterSpacing = '0px';
				canvasContext.fillStyle = '#000000';
				let lineHeight = fontSize * 1.4;
				canvasContext.fillText(
					'Te invitan a celebrar',
					image.width / 2,
					textTopAlignment,
				);
				textTopAlignment += (lineHeight / sizeFactor);
				canvasContext.fillText(
					'su casamiento',
					image.width / 2,
					textTopAlignment,
				);
				textTopAlignment += (lineHeight / sizeFactor);
				canvasContext.fillText(
					'Domingo 14 de Enero de 2024',
					image.width / 2,
					textTopAlignment,
				);
				textTopAlignment += (lineHeight / sizeFactor);
				canvasContext.fillText(
					'12:00 pm',
					image.width / 2,
					textTopAlignment,
				);
				textTopAlignment += (lineHeight / sizeFactor);
				canvasContext.fillText(
					'Estación Fátima Eventos | Fátima',
					image.width / 2,
					textTopAlignment,
				);
				textTopAlignment += (lineHeight / sizeFactor);
				fontSize = 12;
				canvasContext.font = `${fontSize / sizeFactor}px Oranienbaum Regular`;
				lineHeight = fontSize * 1.4;
				canvasContext.fillText(
					'Invitación solo a mayores de 16 años',
					image.width / 2,
					textTopAlignment,
				);
				console.log(this.canvasElement.toDataURL('image/jpeg'));
			}
		};
		// @ts-ignore
		image.src = (await import('../app/assets/invitation.jpg')).default;
	}
}
