<div><div :style="{
			fontFamily: 'Julietta',
			height: '0px',
			width: '0px',
			opacity: 0,
			visibility: 'hidden',
		}">Some Text</div><div :style="{
			fontFamily: 'Oranienbaum Regular',
			height: '0px',
			width: '0px',
			opacity: 0,
			visibility: 'hidden',
		}">Some Text</div><canvas ref="canvas"></canvas></div>