import {
	GetObjectCommand,
	PutObjectCommand,
	S3Client,
} from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { createEvent } from 'ics';
import {
	Component,
	Ref,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'AppModule',
	mixins: [
		Template,
	]
})
export default class AppModule extends Vue {
	@Ref('dialogModal')
	private dialogModalElement!: HTMLDivElement;

	protected rsvpDialogVisible: boolean = false;

	private rsvpName = '';

	private rsvpValue: RSVPChoice | null = null;

	private s3Client?: S3Client;

	protected beforeDestroy(): void {
		this.s3Client?.destroy();
	}

	protected created(): void {
		this.s3Client = new S3Client({
			credentials: fromCognitoIdentityPool({
				clientConfig: {
					region: 'sa-east-1',
				},
				identityPoolId: 'sa-east-1:cd820f86-c79b-4464-8f7a-4d7ef3dc7fc7',
			}),
			region: 'sa-east-1',
		});
	}

	protected onAddToCalendarClick(): void {
		this.rsvpDialogVisible = true;
	}

	protected onDialogCloseClick(): void {
		this.rsvpDialogVisible = false;
		this.rsvpName = '';
		this.rsvpValue = null;
	}

	protected onDialogModalClick(event: MouseEvent | TouchEvent): void {
		if (event.target === this.dialogModalElement) {
			this.onDialogCloseClick();
		}
	}

	protected async onDialogConfirmClick(): Promise<void> {
		let rsvpContent: RSVPContent;

		try {
			const s3File = await this.s3Client?.send(
				new GetObjectCommand({
					Bucket: 'marianayjulian-rsvp',
					Key: 'rsvp.json',
				}),
			);

			if (s3File?.Body) {
				rsvpContent = JSON.parse(await s3File.Body.transformToString());
				rsvpContent.rsvp.push({
					choice: this.rsvpValue!,
					fullName: this.rsvpName,
				});
			} else {
				throw new Error('No RSVP file found');
			}
		} catch {
			rsvpContent = {
				rsvp: [
					{
						choice: this.rsvpValue!,
						fullName: this.rsvpName,
					},
				],
			};
		}

		await this.s3Client?.send(
			new PutObjectCommand({
				Bucket: 'marianayjulian-rsvp',
				Key: 'rsvp.json',
				Body: JSON.stringify(rsvpContent),
			}),
		);

		createEvent(
			{
				start: [2024, 1, 14, 12, 0],
				duration: {
					hours: 9,
				},
				title: 'Casamiento de Mariana y Julián',
				location: 'Isla Jorge 290, Fátima, Provincia de Buenos Aires',
				url: 'https://marianayjulian.com/casamiento',
				geo: {
					lat: -34.4357713,
					lon: -58.9949308,
				},
			},
			(error, value) => {
				if (
					!error
					&& value
				) {
					const file = new File(
						[value],
						'Casamiento-Mariana-Julian.ics',
						{
							type: 'text/calendar',
						},
					);
					const url = URL.createObjectURL(file);
					const link = document.createElement('a');
					link.href = url;
					link.download = file.name;
					link.click();
					link.remove();
					URL.revokeObjectURL(url);
				}

				this.onDialogCloseClick();
			}
		);
	}

	protected onNavigateClick(section: string): void {
		const sectionElement = this.$refs[section] as HTMLElement | undefined;

		if (sectionElement) {
			sectionElement.scrollIntoView({
				behavior: 'smooth',
			});
		}
	}

	protected onRsvpChoiceClick(choice: RSVPChoice): void {
		this.rsvpValue = choice;
	}
}
