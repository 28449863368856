// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./core-sans.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./core-sans.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./core-sans.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./julietta.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Oranienbaum-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:"Core Sans Regular";src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("embedded-opentype"),url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"),url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype")}@font-face{font-family:"Julietta";src:url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype")}@font-face{font-family:"Oranienbaum Regular";src:url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype")}`, "",{"version":3,"sources":["webpack://./src/fonts/index.scss"],"names":[],"mappings":"AAAA,WACI,+BAAA,CACA,yLAAA,CAKJ,WACI,sBAAA,CACA,8DAAA,CAGJ,WACI,iCAAA,CACA,8DAAA","sourcesContent":["@font-face {\n    font-family: 'Core Sans Regular';\n    src: url('./core-sans.eot') format('embedded-opentype'),\n\t\turl('./core-sans.woff') format('woff'),\n\t\turl('./core-sans.ttf') format('truetype');\n}\n\n@font-face {\n    font-family: 'Julietta';\n    src: url('./julietta.ttf') format('truetype');\n}\n\n@font-face {\n    font-family: 'Oranienbaum Regular';\n    src: url('./Oranienbaum-Regular.ttf') format('truetype');\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
