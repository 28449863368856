import {
	createRouter,
	createWebHistory,
} from 'vue-router';
import App from './modules/app';
import Invitation from './modules/invitation';

export default createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/:pathMatch(.*)*',
			component: App,
		},
		{
			path: '/',
			redirect: '/casamiento',
		},
		{
			path: '/invitacion',
			component: Invitation,
		},
		{
			path: '/fotos',
			component: {},
			beforeEnter() {
				window.location.href = 'https://photos.app.goo.gl/SYgJaihVPuEMgnN19';
			}
		}
	],
});
